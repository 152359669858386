import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { Layout } from '../components/shared/Layout';
import { SEO } from '../components/shared/seo';

import { Program } from '../components/pages/Program';
import { IEvent } from '../types/event';

interface DataProps {
  events: {
    edges: IEvent[];
  };
}
const ProgramPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  return (
    <Layout padded={true}>
      <SEO title="Programma" />
      <Program events={data.events.edges} />
    </Layout>
  );
};

export default ProgramPage;

export const query = graphql`
  query {
    events: allMarkdownRemark(filter: { frontmatter: { post_type: { eq: "event" } } }) {
      edges {
        node {
          id
          frontmatter {
            event_id
            title
            slug
            categories
            cover {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            activities {
              id
              frontmatter {
                activity_type
                date_start(formatString: "YYYY-MM-DD H:mm")
                date_end(formatString: "YYYY-MM-DD H:mm")
                price
                booking_type
                booking_required
              }
            }
          }
        }
      }
    }
  }
`;
