import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import moment from 'moment';

import styles from './program.module.scss';
import { IEvent } from '../../../types/event';
import { IActivity } from '../../../types/activity';

interface DataProps {
  events: IEvent[];
}

export const Program: React.FC<DataProps> = ({ events }) => {
  const e = events.map((item: any) => item.node);

  // const filterByDay = (day: string, events: IEvent[]) => {
  //   console.log('Filterbydate: ', day);
  //   let filteredEvents: IEvent[] = [];

  //   events.map((event: IEvent) => {
  //     if (event.frontmatter.activities) {
  //       // console.log('Check event: ', event.frontmatter.event_id);
  //       event.frontmatter.activities.map((activity: IActivity) => {
  //         const d = moment(activity.date_start, 'YYYY-MM-DD H:mm');
  //         // console.log(d.isValid);
  //         // console.log(day);
  //         // console.log(moment(day, 'YYYY-MM-DD'));
  //         // console.log(d.isSame(moment(day, 'YYYY-MM-DD'), 'day'));

  //         // const isValidActivity = d.isValid && d.isSame(moment(day, 'YYYY-MM-DD'), 'day');
  //         // console.log('isvalid', isValidActivity);
  //         // // if isvalid then add event to the array
  //         // if (isValidActivity) {
  //         //   console.log('isvalid');
  //         //   if (filteredEvents.indexOf(event) === -1) {
  //         //     // delete event.frontmatter.activities;
  //         //     filteredEvents.push(event);
  //         //   } else {
  //         //     // console.log('event already existsd');
  //         //   }
  //         // }
  //       });
  //     }
  //   });
  //   console.log(filteredEvents);
  //   return events.filter(
  //     (event: IEvent) => event.frontmatter.listing_days && event.frontmatter.listing_days.indexOf(day) !== -1,
  //   );
  // };

  return (
    <div className={styles['program-wrapper']}>
      <div className="container">
        <h1>Programma</h1>
        <ProgramRow title="Maandag 23 november" day="2020-11-23" events={e} />
        <ProgramRow title="Dinsdag 24 november" day="2020-11-24" events={e} />
        <ProgramRow title="Woensdag 25 november" day="2020-11-25" events={e} />
        <ProgramRow title="Donderdag 26 november" day="2020-11-26" events={e} />
        <ProgramRow title="Vrijdag 27 november" day="2020-11-27" events={e} />
        <ProgramRow title="Zaterdag 28 november" day="2020-09-28" events={e} />
        <ProgramRow title="Zondag 29 november" day="2020-09-29" events={e} />
      </div>
    </div>
  );
};

interface ProgramRowData {
  title: string;
  day: string;
  events: IEvent[];
}
const ProgramRow: React.FC<ProgramRowData> = ({ title, day, events }) => {
  return (
    <div className={styles['program__day-row']}>
      <h2 className={styles['program__title-date']}>{title}</h2>
      <div className="row">
        {events.map((event: IEvent, index: number) => {
          // Only add if there are activities in this event.
          if (event.frontmatter.activities) {
            let currentActivities: IActivity[] = [];

            event.frontmatter.activities.map((activity: IActivity) => {
              if (!activity) return;

              const startDate = moment(activity.date_start, 'YYYY-MM-DD H:mm');
              const validDate = startDate.isValid();
              const sameDate = startDate.isSame(moment(day, 'YYYY-MM-DD'), 'day');

              if (validDate && sameDate) {
                currentActivities.push(activity);
              }
            });
            // Add to calendar if there are activities
            if (currentActivities.length > 0) {
              return (
                <div key={index} className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                  <Link to={`/${event.frontmatter.slug}`} className={styles['program-item__link']}>
                    {event.frontmatter.cover && (
                      <Img fluid={event.frontmatter.cover.childImageSharp.fluid} className={styles['image-wrapper']} />
                    )}
                    <div className={styles['program-item__date-wrapper']}>
                      {currentActivities &&
                        currentActivities.map((activity: IActivity, index: number) => (
                          <span key={index} className={styles['program-item__date']}>
                            {activity.type} | {moment(activity.date_start).format('H:mm')}-
                            {moment(activity.date_end).format('H:mm')}
                          </span>
                        ))}
                    </div>
                    <h3 className={styles['program-item__title']}>{event.frontmatter.title}</h3>
                  </Link>
                </div>
              );
            }
          }
        })}
      </div>
    </div>
  );
};
